import { baseRoutes } from '../../core/config/routes';

export const routes = {
  ...baseRoutes,
  admin: {
    ...baseRoutes.admin,
    clients: {
      list: '/admin/clients',
      new: '/admin/clients/new',
      edit: '/admin/clients/:id',
    },
    clientRepresentatives: {
      new: '/admin/clients/:client/representatives',
      edit: '/admin/clients/client-representatives/:id',
    },
    projects: {
      list: '/admin/projects',
      new: '/admin/projects/new',
      edit: '/admin/projects/:id',
    },
    employees: {
      list: '/admin/employees',
      new: '/admin/employees/new',
      edit: '/admin/employees/:id',
    },
    tasks: {
      list: '/admin/tasks',
      details: '/admin/tasks/:id',
    },
    clientTasks: {
      list: '/admin/client-tasks',
    },
    invoices: {
      list: '/admin/invoices',
      edit: '/admin/invoices/:id',
    },
    invoiceItems: {
      list: '/admin/invoice-items',
      edit: '/admin/invoice-items/:id',
    },
    taskWorkLogs: {
      list: '/admin/work-logs',
    },
  },
  auth: {
    ...baseRoutes.auth,
  },
};
